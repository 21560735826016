import { PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/ProductImage/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const PRODUCT_ENTITY_LIST_FRAGMENT = `
  fragment productEntityListFragment on Product {
    id
    uid
    schemaCode
    __typename
    __typename
    name
    images {
      ...productImageBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
  }
  ${PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
`;
